.title-logo{
  padding-right: 15px;
  height: 32px;
  width: 32px;
  box-sizing: content-box;
}

.chain-icon{
  padding-right: 10px;
  box-sizing: content-box;
  height: 20px;
  width: 20px;
}

.Minter {
  max-width: 80vh;
  max-height: 80vh;
  padding: 60px 100px;
  margin: 0 auto;
  text-align: left;

}

.MyTokens {
  margin-top:75px;
  padding-bottom:75px;
  text-align: left;

}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(243, 176, 51, 0.2);
  line-height: 32px;
}

input::placeholder{
  font-family: 'Cutive Mono';
  font-size: 12px;
}

input:focus {
  outline: none;
  border-color: rgba(243, 176, 51, 0.5);
}

a{
  text-decoration: none;
}

#status {
  z-index: 1000;
  margin-top: -24px;
}

#mintBarLoader {
  margin-top: 30px;
  margin-bottom: 110px;
}

#maxButton, #supportButton {
  padding: 0px 8px;
  border: none;
  opacity: 70%;
}

#media-token {
  max-height: 250px;
}

// Animation for Button Shake-------------------------
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.shakeElement {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}