// Update bootstrap values
 $primary: rgb(243, 176, 51);
 $btn-font-weight: bold;
 $card-border-color: rgb(243, 176, 51);

// Increased default heading font weight
$headings-font-weight: 750;

// Update spacers for more values, for ex mt-8
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
$spacers: map-merge((
    6: ($spacer * 4),
    7: ($spacer * 5),
    8: ($spacer * 6)), $spacers);

@import "../index.scss";
@import "~bootstrap/scss/bootstrap.scss";

// Overwrite variables
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');

:root {
    --bs-body-font-family: 'Cutive Mono';
    --bs-body-color: rgb(68, 60, 60);
}

.popover{
    font-family: 'Cutive Mono';
    --bs-popover-border-color: rgb(243, 176, 51);
    --bs-popover-max-width: 345px;
}

.table > :not(caption) > * > * {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem; 
}

.table {
    --bs-table-striped-bg: rgba(243, 176, 51, 0.05);
}

.tooltip{
    font-family: 'Cutive Mono';
}